import React from "react";
import styled from "styled-components";
import colors from "styles/colors";

const StyledFooter = styled.footer`
  color: white;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 0.5rem 0;
  background: ${colors.backgroundDarker};
  display: flex;
  justify-content: center; /* Center the content horizontally */
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  opacity: 0.75;
  transition: all 0.2s ease-in-out;
  &:hover {
    opacity: 1;
  }
`;

const Link = styled.a`
  color: ${colors.primary};
  font-weight: bold;
  border-radius: 4px;
  padding: 0.1rem;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: ${colors.primary};
    color: ${colors.backgroundDarker};
    text-decoration: none;
  }
`;

const RedmorphInc = styled.span`
  margin: 0 0.5rem;
  text-align: center;
  color: #ce452b; /* Red color */
  cursor: pointer;
`;

const CopyrightSymbol = styled.span`
  font-size: 1.5em; /* Adjust the size as needed */
  margin-right: 0.2em; /* Add space to the right of the symbol */
`;

const Footer = (props: { isFixed?: boolean }): JSX.Element => {
  return (
    <StyledFooter style={props.isFixed ? { position: "fixed" } : {}}>
      <CopyrightSymbol>©</CopyrightSymbol>
      <span> 2019-2024 All rights reserved by </span>
      <RedmorphInc
        onClick={() => (window.location.href = "https://redmorph.com")}
      >
        Redmorph Inc.
      </RedmorphInc>
    </StyledFooter>
  );
};

export default Footer;
