import styled from "styled-components";
import { ChangeEvent, FormEvent, useState, useEffect } from "react";
import { useNavigate, NavigateOptions } from "react-router-dom";

import Heading from "components/Form/Heading";
import Input from "components/Form/Input";
import Button from "components/Form/Button";
import { StyledCard } from "components/Form/Card";
import Footer from "components/misc/Footer";
import FancyBackground from "components/misc/FancyBackground";
import MatrixEffect from "components/misc/MatrixEffect";
import MatrixRainEffect from "components/misc/MatrixRainEffect";

import docs from "utils/docs";
import colors from "styles/colors";
import { determineAddressType } from "utils/address-type-checker";
import RedmorphHTML from "./Website-X";
import "./Scanner.css";

const HomeContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-family: "PTMono";
  padding: 0 1rem;
  scroll-behavior: smooth;
  footer {
    z-index: 1;
  }
  body {
    overflow: auto;
  }

  html {
    scroll-behavior: smooth;
  }
`;

const UserInputMain = styled.form`
  background: rgba(26, 35, 50, 0);
  // background: ${colors.backgroundLighter};
  // box-shadow: 4px 4px 0px ${colors.bgShadowColor};
  border-radius: 8px;
  padding: 1rem;
  z-index: 5;
  margin: 1rem 10rem 15rem;
  @media (max-width: 768px) {
    /* Adjust margin for mobile devices */
    margin: 1rem 5rem 5rem;
  }
  width: calc(100% - 2rem);
  max-width: 50rem;
  z-index: 2;
  scroll-behavior: smooth;
  // margin-top: 1rem;
`;

// const FindIpButton = styled.a`
//   margin: 0.5rem;
//   cursor: pointer;
//   display: block;
//   text-align: center;
//   color: ${colors.primary};
//   text-decoration: underline;
// `;

const ErrorMessage = styled.p`
  color: ${colors.danger};
  margin: 0.5rem;
`;

const SiteFeaturesWrapper = styled(StyledCard)`
  margin: 1rem;
  width: calc(100% - 2rem);
  max-width: 50rem;
  z-index: 2;
  .links {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    a {
      width: 100%;
      button {
        width: calc(100% - 2rem);
      }
    }
    @media (max-width: 600px) {
      flex-wrap: wrap;
    }
  }
  ul {
    -webkit-column-width: 150px;
    -moz-column-width: 150px;
    column-width: 150px;
    list-style: none;
    padding: 0 1rem;
    font-size: 0.9rem;
    li {
      margin: 0.1rem 0;
      text-indent: -1.2rem;
      break-inside: avoid-column;
    }
    li:before {
      content: "✓";
      color: ${colors.primary};
      margin-right: 0.5rem;
    }
  }
  a {
    color: ${colors.primary};
  }
`;

const boxStyle = {
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "2em",
};

// Add the new styles for .tag-text and .logo-text
const LogoText = styled.div`
  text-align: center;
  z-index: 1;
  margin-top: 5rem;
  margin-bottom: 0rem;

  @media (max-width: 768px) {
    /* Adjust margin for mobile devices */
    margin-top: 7rem;
  }
`;

const TagText = styled.p`
  color: white;
  margin-top: 1rem;
  margin-bottom: 0rem;
`;

const Home = (): JSX.Element => {
  const defaultPlaceholder = "Please enter URL to scan";
  const [userInput, setUserInput] = useState("");
  const [errorMsg, setErrMsg] = useState("");
  const [placeholder] = useState(defaultPlaceholder);
  const [inputDisabled] = useState(false);
  const navigate = useNavigate();
  const [sessionToken, setSessionToken] = useState<string | null>(null);
  // Fetch session token when the component mounts
  useEffect(() => {
    // Fetch session token from cookie on component mount
    const sessionToken = getSessionTokenFromCookie();
    if (sessionToken) {
      // Use the retrieved session token for subsequent requests
      submit();
    } else {
      // If session token doesn't exist in cookie, fetch new session token
      fetchSessionToken();
    }
  }, []);

  // Function to fetch session token from cookie
  const getSessionTokenFromCookie = () => {
    const { cookie } = document;
    const sessionTokenCookie = cookie
      .split(";")
      .find((c) => c.trim().startsWith("session_token="));
    if (sessionTokenCookie) {
      return sessionTokenCookie.split("=")[1];
    }
    return null;
  };

  // Function to fetch session token from the backend or retrieve from cookie
  // Function to fetch session token from backend
  const fetchSessionToken = async () => {
    try {
      const sessionToken = getSessionTokenFromCookie();
      if (!sessionToken) {
        throw Error("No existing session token found.");
      }
      // const response = await fetch("/api/get-session-token");
      // if (response.ok) {
      //   const data = await response.json();
      //   // Save the session token to cookie
      //   document.cookie = `session_token=${data.sessionToken}; path=/`;
      //   // Use the retrieved session token for subsequent requests
      //   submit(data.session_token);
      // } else {
      //   throw new Error("Failed to fetch session token");
      // }
    } catch (error) {
      console.log("Error fetching session token:", error);
    }
  };

  /* Check is valid address, either show err or redirect to results page */
  const submit = () => {
    let address = userInput.endsWith("/") ? userInput.slice(0, -1) : userInput;
    const addressType = determineAddressType(address);

    if (addressType === "empt") {
      setErrMsg("Please enter URL to scan");
    } else if (addressType === "err") {
      setErrMsg("Please enter URL to scan");
    } else {
      // if the addressType is 'url' and address doesn't start with 'http://' or 'https://', prepend 'https://'
      if (addressType === "url" && !/^https?:\/\//i.test(address)) {
        address = "https://" + address;
      }
      // Store the parameters in local storage
      localStorage.setItem(
        "scanParams",
        JSON.stringify({ address, addressType })
      );

      const resultRouteParams: NavigateOptions = {
        state: { address, addressType },
      };
      navigate(`/results/${encodeURIComponent(address)}`, resultRouteParams);
    }
  };

  // const submit = () => {
  //   // Fetch the session token when the form is submitted
  //   fetchSessionToken();
  //   let address = userInput.endsWith("/") ? userInput.slice(0, -1) : userInput;
  //   const addressType = determineAddressType(address);

  //   if (addressType === "empt") {
  //     setErrMsg("Please enter URL to scan");
  //   } else if (addressType === "err") {
  //     setErrMsg("Please enter URL to scan");
  //   } else {
  //     // if the addressType is 'url' and address doesn't start with 'http://' or 'https://', prepend 'https://'
  //     if (addressType === "url" && !/^https?:\/\//i.test(address)) {
  //       address = "https://" + address;
  //     }
  //     // Store the parameters in local storage
  //     localStorage.setItem(
  //       "scanParams",
  //       JSON.stringify({ address, addressType })
  //     );

  //     const resultRouteParams: NavigateOptions = {
  //       state: { address, addressType },
  //     };
  //     navigate(`/results/${encodeURIComponent(address)}`, resultRouteParams);
  //   }
  // };

  /* Update user input state, and hide error message if field is valid */
  const inputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUserInput(event.target.value);
    const isError = ["err", "empt"].includes(
      determineAddressType(event.target.value)
    );
    if (!isError) {
      setErrMsg("");
    }
  };

  const formSubmitEvent = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    submit();
  };
  return (
    <div className="scanner-container">
      <div className="logo">
        {/* <img src={logo} alt="Redmorph Logo" /> */}
        <img width="256px" src="/rm-logo.png" alt="Redmorph Icon" />
      </div>
      <h2 className="title">
        Ultimate scanner for consolidated website profile and threat vectors
        data
      </h2>
      <div className="search-box">
        <input
          type="text"
          placeholder="Please enter URL to Scan"
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
        />
        <button className="search-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </button>
      </div>
      <button className="search-button" onClick={submit}>
        Search
      </button>
      {errorMsg && <p className="error-message">{errorMsg}</p>}
    </div>
  );
};

// return (
//   <HomeContainer>
//     {/* <FancyBackground /> */}
//     {/* <MatrixRainEffect /> */}
//     <LogoText>
//       <img width="256px" src="/rm-horizontal-white.png" alt="Redmorph Icon" />
//       <TagText>
//         Ultimate scanner for consolidated website profile and threat vectors
//         data.
//       </TagText>
//     </LogoText>
//     <UserInputMain onSubmit={formSubmitEvent}>
//       <Input
//         id="user-input"
//         value={userInput}
//         // label="Enter a URL"
//         size="large"
//         orientation="vertical"
//         placeholder={placeholder}
//         disabled={inputDisabled}
//         handleChange={inputChange}
//       />
//       {/* <FindIpButton onClick={findIpAddress}>Or, find my IP</FindIpButton> */}
//       {errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
//       <Button
//         styles="width: calc(25% - 1rem); margin: 0.5rem auto;"
//         size="medium"
//         onClick={submit}
//       >
//         Scan
//       </Button>
//     </UserInputMain>

//     {/* Include the AdditionalHTMLPage component */}
//     {/* <AdditionalHTMLPage /> */}
//     {/* <RedmorphHTML /> */}
//     {/* <Footer isFixed={false} /> */}
//   </HomeContainer>
// );
// };

export default Home;
