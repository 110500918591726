import styled from "styled-components";
import { ServerLocation } from "utils/result-processor";
import { Card } from "components/Form/Card";
import LocationMap from "components/misc/LocationMap";
import Flag from "components/misc/Flag";
import { TextSizes } from "styles/typography";
import Row, { StyledRow } from "components/Form/Row";

const cardStyles = "";

const SmallText = styled.span`
  opacity: 0.5;
  font-size: ${TextSizes.xSmall};
  text-align: right;
  display: block;
`;

const MapRow = styled(StyledRow)`
  padding-top: 1rem;
  flex-direction: column;
`;

const CountryValue = styled.span`
  display: flex;
  gap: 0.5rem;
`;

const ServerLocationCard = (props: {
  data: ServerLocation;
  title: string;
  actionButtons: any;
}): JSX.Element => {
  const location = props.data;
  const {
    ip,
    network,
    version,
    city,
    region,
    region_code,
    country,
    country_name,
    country_code,
    country_code_iso3,
    country_capital,
    country_tld,
    continent_code,
    in_eu,
    postal,
    latitude,
    longitude,
    timezone,
    utc_offset,
    country_calling_code,
    currency,
    currency_name,
    languages,
    country_area,
    country_population,
    asn,
    org,
  } = location;

  return (
    <Card
      heading={props.title}
      actionButtons={props.actionButtons}
      styles={cardStyles}
    >
      <Row lbl="IP" val={ip} />
      <Row lbl="Network" val={network} />
      <Row lbl="Version" val={version} />
      <Row lbl="City" val={`${postal}, ${city}, ${region}`} />
      <Row lbl="" val="">
        <b>Country</b>
        <CountryValue>
          {country}
          {country_code && <Flag countryCode={country_code} width={28} />}
        </CountryValue>
      </Row>
      <Row lbl="Timezone" val={timezone} />
      <Row lbl="Languages" val={languages} />
      <Row lbl="Currency" val={`${currency} (${currency_name})`} />
      <Row lbl="Country Area" val={country_area.toString()} />
      <Row lbl="Country Population" val={country_population.toString()} />
      <Row lbl="ASN" val={asn} />
      <Row lbl="Organization" val={org} />
      <MapRow>
        <LocationMap lat={latitude} lon={longitude} label={`User (${org})`} />
        <SmallText>
          Latitude: {latitude}, Longitude: {longitude}{" "}
        </SmallText>
      </MapRow>
    </Card>
  );
};

export default ServerLocationCard;
