import React, { useState, useEffect } from "react";
import styled from "styled-components";
import colors from "styles/colors";
import { Card } from "components/Form/Card";

const CardStyles = `
  margin: 0 auto 1rem auto;
  width: 95vw;
  position: relative;
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  a {
    color: ${colors.primary};
  }
  .controls {
    display: flex;
    flex-wrap: wrap;
    button {
      max-width: 300px;
    }
  }
  small {
    margin-top: 0.5rem;
    font-size: 0.8rem;
    opacity: 0.5;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  th,
  td {
    border: 1px solid ${colors.primaryTransparent};
    padding: 0.5rem;
    text-align: left;
  }
  th {
    background-color: ${colors.primary};
    color: white;
  }
`;

const ViewHosts: React.FC<{ hosts: { host: string; ips: string[] }[] }> = (
  props
) => {
  const [hostInfos, setHostInfos] = useState<
    Record<string, Record<string, unknown>>
  >({});

  useEffect(() => {
    const fetchInfo = async (domain: string) => {
      try {
        const response = await fetch(`http://ip-api.com/json/${domain}`);
        const data = await response.json();
        setHostInfos((prevState) => ({ ...prevState, [domain]: data }));
      } catch (error) {
        console.log("Error fetching info:", error);
      }
    };

    props.hosts.forEach((host) => {
      // fetchInfo(host.host);
      host.ips.forEach((ip) => {
        // fetchInfo(ip);
      });
    });
  }, [props.hosts]);

  return (
    <Card heading="View Hosts Data" styles={CardStyles}>
      <Table>
        <thead>
          <tr>
            <th>Host</th>
            <th>IPs and Info</th>
          </tr>
        </thead>
        <tbody>
          {props.hosts.map((host) => (
            <tr key={host.host}>
              <td>
                <strong>{host.host}</strong>
              </td>
              <td>
                <ul>
                  <li>
                    <strong>{host.host}</strong>
                    {hostInfos[host.host] && (
                      <span> - {JSON.stringify(hostInfos[host.host])}</span>
                    )}
                  </li>
                  {host.ips.map((ip) => (
                    <li key={ip}>
                      <strong>{ip}</strong>
                      {hostInfos[ip] && (
                        <span> - {JSON.stringify(hostInfos[ip])}</span>
                      )}
                    </li>
                  ))}
                </ul>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

export default ViewHosts;
