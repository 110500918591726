import Footer from "components/misc/Footer";
import MatrixRainEffect from "components/misc/MatrixRainEffect";
import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import HamburgerMenu from "./HamburgerMenu";
import Home from "./Home";
import "./styles.css"; //
import WebsiteAppSection from "./WebsiteAppSection";
import WebsiteHeader from "./WebsiteHeader";

const TopMargin = styled.div`
  margin-top: 10rem !important;
`;

const WebsiteHTML: React.FC = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    // Initial check on mount
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <html lang="en">
      <WebsiteHeader />
      <body>
        {/* <MatrixRainEffect /> */}
        <section>
          <div>
            {/* <HamburgerMenu /> */}
            {/* <WebsiteAppSection /> */}
            <Home />
          </div>
        </section>
        {/* <Footer isFixed={!isMobile} /> */}
      </body>
    </html>
  );
};

export default WebsiteHTML;
