import React from "react";

const WebsiteHeader = () => {
  return (
    <head>
      <title>RedMorph | Simplified</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="title" property="og:title" content="RedMorph | Simplified" />
      <meta
        name="image"
        property="og:image"
        content="https://redmorph.com/assets1/images/rm_title.png"
      />
      <meta
        name="description"
        content="Redmorph is a powerful all-in-one tool for discovering information about a website/host. It provides proactive control over privacy and security issues, leading to significant data usage savings and improved device performance."
      />
      <meta name="author" content="Redmorph Inc." />
      <meta
        name="keywords"
        content="battery saver, battery, battery saver app, super battery saver, mobile battery saver, android battery saver, increase battery life, power saver battery app, phone battery saver, battery saver battery saver, android battery, my battery saver, mobile battery apps, battery performance, android battery saver mode, battery improvements, battery saver for android phone, battery life saver app, ad blocker, adblock, adblock plus, ad blocker app, remove ads, advertisement blocker app, page load time, stop ads, no ads, add free, advertisement blocker, website load time, loading page, how to stop ads, internet ad blocker, online ad blocker, site load speed, reduce website load time, power saver, power saver app, firewall, what is firewall, firewalls, network firewall, firewall software, firewall security, firewall and security, firewall network protection, data privacy, customer data privacy, personal data privacy, data protection, data protection act, data protection and privacy, what is data protection, personal data protection, privacy data protection, customer data protection, data protection individual rights, internet security, internet security antivirus, internet privacy, privacy and the internet, privacy policy, privacy protection, privacy security, privacy security app, security and privacy, online privacy, privacy and security, security & privacy, data security and privacy, protect your privacy, cyber privacy, web privacy, browser vpn privacy and security online, information security, powersaves, security apps, antivirus app, mobile security, android security, mobile hacker, ransomware protection, android security apps, tracking protection, malicious app, android protected apps"
      />
      <link
        rel="icon"
        type="image/x-icon"
        href="./assets1/images/favicon.png"
      />
      <script src="./assets1/js/bootstrap.bundle.min.js"></script>
      <script src="./assets1/js/angular.min.js"></script>
      <link rel="stylesheet" href="./assets1/css/bootstrap.min.css" />
      <link rel="stylesheet" href="./assets1/css/style.css" />
    </head>
  );
};

export default WebsiteHeader;
